<template>
  <EmailSent :icon="require('../../../../assets/icons/icn_email.svg')"
             :title="$t('RESETLINK.TITLE')"
             :button-text="$t('BACK_TO_LOGIN')"
             :route-name="ROUTE_NAMES_AUTH.LOGIN">
    <template #text>
      <i18n-t keypath="RESETLINK.SUBTITLE" tag="p">
        <template #email>
          <span class="email"> {{ email }} </span>
        </template>
      </i18n-t>
    </template>
  </EmailSent>
</template>

<script>
import EmailSent from '@/components/elements/auth/EmailSent'
import { ROUTE_NAMES_AUTH } from '@/router/modules/auth'
import { useRoute, useRouter } from 'vue-router'

export default {
  name: 'ResetLinkSent',
  components: {
    EmailSent
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const email = route.params.email

    //return back to login if refreshing
    if (!email) {
      router.push({ name: ROUTE_NAMES_AUTH.LOGIN })
    }


    return {
      email,
      ROUTE_NAMES_AUTH
    }
  }
}
</script>

<style lang="scss" scoped>
.email {
  font-weight: 600;
}
</style>
